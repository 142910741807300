import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { elementCategories } from '../constants/elementCategories';

const Loader = ({itemId, size}) => {
  const {t} = useTranslation();

  const [elementNumber, setElementNumber] = useState(78);

  function getRandomInt() {
    return Math.floor(Math.random() * 127) + 1;
  }

  useEffect(() => {
    if (itemId) {
      setElementNumber(itemId)
    } else {
      setElementNumber(getRandomInt());
    }
  }, [itemId]);

  return (
    <div className={`loader loader--${size} loader--${elementCategories[elementNumber - 1]}`}>
      <div className='loader__element'>
        <div className='loader__symbol'>{t(`table.element${elementNumber}.symbol`)}</div>
        <div className='loader__name'>{t(`table.element${elementNumber}.name`)}</div>
      </div>
    </div>
  )
}

export default Loader;