import { useEffect } from 'react';

export const addShadowOnScroll = (setStartState, setEndState, ref) => {
  const onScroll = () => {
    const {
      scrollHeight = 0,
      scrollTop = 0,
      offsetHeight = 0
    } = ref.current || {};
    setStartState(scrollTop > 0);
    setEndState(scrollTop + offsetHeight < scrollHeight);
  };
  onScroll();
  const node = ref.current;
  node.addEventListener('scroll', onScroll);
  return () => {
    node.removeEventListener('scroll', onScroll);
  };
};

export const useOutsideClick = (ref, setClose) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setClose(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, setClose]);
};

export const changeCategoryColor = category => {
  switch (category) {
    case 'A':
      return '#CE2D4F';
    case 'B':
      return '#20A4F3';
    case 'C':
      return '#FF8600';
    case 'D':
      return '#006DA1';
    case 'E':
      return '#C41E3D';
    case 'G':
      return '#FAA916';
    case 'H':
      return '#43894E';
    case 'I':
      return '#662e9b';
    case 'J':
      return '#D84A05';
    case 'K':
      return '#52713F';
    case 'L':
      return '#F5F5F5';
    default:
      return '#F5F5F5';
  }
};

export const changeWhiteColor = category => {
  switch (category) {
    case 'L':
      return '#000000';
    default:
      return '#F5F5F5';
  }
};

export const getValuesWithHTML = (value, unit) => {
  if (value === null) {
    return {
      __html: '----'
    };
  } else {
    return {
      __html: `${value}${unit}`
    };
  }
};

export const setFontSizeVariable = () => {
  const fontSize = localStorage.getItem('readElementFontSize');
  switch (fontSize) {
    case '0':
      document.documentElement.style.setProperty(
        '--read-element-font-size',
        '12px'
      );
      break;
    case '1':
      document.documentElement.style.setProperty(
        '--read-element-font-size',
        '14px'
      );
      break;
    case '2':
      document.documentElement.style.setProperty(
        '--read-element-font-size',
        '16px'
      );
      break;
    default:
      document.documentElement.style.setProperty(
        '--read-element-font-size',
        '14px'
      );
      break;
  }
};
