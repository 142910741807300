const api = `${process.env.REACT_APP_API_HOST}/periodic/all`;

export const getAllElements = async (token) => {
  try {
    const headers = {
      'Content-Type': 'application/json'
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(api, {
      method: 'GET',
      headers: headers
    });

    return await response.json();
  } catch (error) {
    console.error(error);
  }
};