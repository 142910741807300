import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MainMenu from './MainMenu';
import PeriodicTable from './PeriodicTable';
import MobileModal from '../pages/MobileModal.js';
import ReadElementPage from '../pages/ReadElementPage.js';
import SearchPage from '../pages/SearchPage.js';
import ElementListPage from '../pages/ElementListPage.js';
import { useEffect, useState } from 'react';
import { isBrowser, isIPad13, isMobile } from 'react-device-detect';
import i18next from 'i18next';
import { YMInitializer } from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import TablesPage from '../pages/TablesPage.js';
import Table1 from './Table1';
import Table5 from './Table5';
import Table4 from './Table4';
// import Table6 from './Table6';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage.js';
import PrivacyPolicyIosPage from '../pages/PrivacyPolicyIosPage.js';
import SettingsPage from '../pages/SettingsPage';
import LanguageSelectPage from '../pages/LanguageSelectPage';
import { setFontSizeVariable } from '../services/functions';
import Table2 from './Table2';
import Table3 from './Table3';
import { useAuth } from '../hooks/useAuth';
import CookieNotification from './CookieNotification';
import { getAllElements } from '../services/getAllElements';
import { useRecoilState } from 'recoil';
import { allElementsState } from '../states/atoms';
import Loader from './Loader';

const App = () => {
  const { checkIsAuth, getAuthCookie } = useAuth();
  useEffect(() => {
    ReactGA.initialize('UA-197143464-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { t } = useTranslation();
  const [, setAllElementsData] = useRecoilState(allElementsState);
  const [isLoading, setIsLoading] = useState(true);

  const initializeAuthAndFetchData = async () => {
    await checkIsAuth();
    const allElements = await getAllElements(getAuthCookie());
    setAllElementsData(allElements);
    setIsLoading(false);
  };


  useEffect(() => {
    setFontSizeVariable();
    initializeAuthAndFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    if (isMobile || isIPad13) {
      return (
        <>
          {window.location.pathname !== '/privacy-policy' &&
          window.location.pathname !== '/privacy-policy-ios' ? (
            <MobileModal />
          ) : null}
          <Router>
            <Switch>
              <Route
                path='/privacy-policy'
                exact
                render={() => <PrivacyPolicyPage />}
              />
              <Route
                path='/privacy-policy-ios'
                exact
                render={() => <PrivacyPolicyIosPage />}
              />
            </Switch>
          </Router>
        </>
      );
    } else if (isBrowser) {
      return (
        !isLoading ? <>
            <Helmet>
              <html lang={i18next.language.toUpperCase()} />
              <title>{`${t(
                'string.translate.name_ab'
              )} ${new Date().getFullYear()}`}</title>
            </Helmet>
            <YMInitializer
              accounts={[62339872]}
              options={{
                webvisor: true,
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true
              }}
              version='2'
            />
            <CookieNotification />
            <Router>
              {window.location.pathname !== '/privacy-policy' &&
              window.location.pathname !== '/privacy-policy-ios' ? (
                <section className='page-main'>
                  <MainMenu />
                  <div className='page-main__wrapper'>
                    <PeriodicTable />
                  </div>
                </section>
              ) : null}
              <Switch>
                <Route
                  path='/element/:id'
                  render={({ match }) => {
                    const { id } = match.params;
                    return <ReadElementPage id={id} />;
                  }}
                />
                <Route
                  path='/privacy-policy'
                  exact
                  render={() => <PrivacyPolicyPage />}
                />
                <Route
                  path='/privacy-policy-ios'
                  exact
                  render={() => <PrivacyPolicyIosPage />}
                />
                <Route path='/search' render={() => <SearchPage />} />
                <Route path='/element-list' render={() => <ElementListPage />} />
                <Route path='/tables' exact component={TablesPage} />
                <Route path='/settings' exact component={SettingsPage} />
                <Route
                  path='/settings/language'
                  exact
                  component={LanguageSelectPage}
                />
                <Route path='/tables/table-1' component={Table1} />
                <Route path='/tables/table-2' component={Table2} />
                <Route path='/tables/table-3' component={Table3} />
                <Route path='/tables/table-4' component={Table4} />
                <Route path='/tables/table-5' component={Table5} />
                {/*<Route path='/tables/table-4' component={Table6} />*/}
              </Switch>
            </Router>
          </> :
          <Loader />
      );
    }
  };

  return <>{renderContent()}</>;
};

export default App;
