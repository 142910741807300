import { Helmet } from 'react-helmet';
import GoToHomePage from './GoToHomePage';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const Table2 = () => {
  const { t } = useTranslation();
  const [selectedIndicator, setSelectedIndicator] = useState(0);

  return (<>
    <Helmet>
      <title>{`${t('string.translate.el_config').replace(':', '')} – ${t('string.translate.name_ab')} ${new Date().getFullYear()}`}</title>
    </Helmet>
    <section className='table-3'>
      <GoToHomePage classNames='go-to-homepage go-to-homepage__full-size' />
      <div className='table-3__modal'>
        <div className='table-3__modal-wrapper'>
          <div className='table-3__header'>
            <Link className='table-3__close-button' to='/tables' />
            <h2 className='table-3__title'>
              {t('string.translate.ph_rastvor_ab')}
            </h2>
          </div>
          <div className='table-3__content'>
            <div className='table-3__card'>
              <div className={`table-3__description-container table-3__description-container--${selectedIndicator}`}>
                <div className={`table-3__description-icon ${`table-3__description-icon--${selectedIndicator}`}`}/>
                <b className='table-3__indicator-name'>
                  {selectedIndicator === 0 && t('string.translate.ph_rastvor_name1')}
                  {selectedIndicator === 1 && t('string.translate.ph_rastvor_name2')}
                  {selectedIndicator === 2 && t('string.translate.ph_rastvor_name3')}
                  {selectedIndicator === 3 && t('string.translate.ph_rastvor_name0')}
                </b>
                <p className='table-3__indicator-description'>
                  {selectedIndicator === 0 && t('string.translate.ph_rastvor_name1_descr')}
                  {selectedIndicator === 1 && t('string.translate.ph_rastvor_name2_descr')}
                  {selectedIndicator === 2 && t('string.translate.ph_rastvor_name3_descr')}
                  {selectedIndicator === 3 && t('string.translate.ph_rastvor_name0_descr')}
                </p>
              </div>
            </div>
            <div className={`table-3__formulas-container table-3__formulas-container--${selectedIndicator}`}>
              <div className='table-3__formula-wrapper'>
                <div className='table-3__formula-name table-3__formula-name--1'>{t('string.translate.ph_rastvor_kislaya')}</div>
                <div className='table-3__formula'>{`[H+] > [OH-] pH`} <b>= 0</b></div>
              </div>
              <div className='table-3__formula-wrapper'>
                <div className='table-3__formula-name table-3__formula-name--2'>{t('string.translate.ph_rastvor_neitr')}</div>
                <div className='table-3__formula'>{`[H+] = [OH-] pH`} <b> = 7</b></div>
              </div>
              <div className='table-3__formula-wrapper'>
                <div className='table-3__formula-name table-3__formula-name--3'>{t('string.translate.ph_rastvor_sheloch')}</div>
                <div className='table-3__formula'>{`[OH-] > [H+] pH`} <b> = 14</b></div>
              </div>
            </div>
          </div>
          <div className='table-3__indicator-select-wrapper'>
            <button className={`table-3__indicator-select table-3__indicator-select--1 ${selectedIndicator === 0 && 'table-3__indicator-select--current'}`} onClick={() => setSelectedIndicator(0)}>
              <div className='table-3__indicator-select-icon'>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M9 1H19C19.5304 1 20.0391 1.21071 20.4142 1.58579C20.7893 1.96086 21 2.46957 21 3V19L19 18.13V3H7C7 2.46957 7.21071 1.96086 7.58579 1.58579C7.96086 1.21071 8.46957 1 9 1Z'
                    fill='#3066BE' />
                  <path d='M3 7C3 5.89543 3.89543 5 5 5H15C16.1046 5 17 5.89543 17 7V11H3V7Z' fill='#3066BE' />
                  <path d='M3 11H17V17H3V11Z' fill='#3066BE' fill-opacity='0.7' />
                  <path d='M3 17H17V21C17 22.1046 16.1046 23 15 23H5C3.89543 23 3 22.1046 3 21V17Z' fill='#3066BE'
                        fill-opacity='0.4' />
                </svg>
              </div>
              <span className='table-3__indicator-select-name'>
                    {t('string.translate.ph_rastvor_name1')}
                  </span>
            </button>
            <button className={`table-3__indicator-select table-3__indicator-select--2 ${selectedIndicator === 1 && 'table-3__indicator-select--current'}`} onClick={() => setSelectedIndicator(1)}>
              <div className='table-3__indicator-select-icon'>
                <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                  <path
                    d='M18.32,8H5.67L5.23,4H18.77M12,19A3,3 0 0,1 9,16C9,14 12,10.6 12,10.6C12,10.6 15,14 15,16A3,3 0 0,1 12,19M3,2L5,20.23C5.13,21.23 5.97,22 7,22H17C18,22 18.87,21.23 19,20.23L21,2H3Z'
                    fill='#CE2D4F' />
                </svg>
              </div>
              <span className='table-3__indicator-select-name'>
                    {t('string.translate.ph_rastvor_name2')}
                  </span>
            </button>
            <button className={`table-3__indicator-select table-3__indicator-select--3 ${selectedIndicator === 2 && 'table-3__indicator-select--current'}`} onClick={() => setSelectedIndicator(2)}>
              <div className='table-3__indicator-select-icon'>
                <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                  <path
                    d='M12 16C13.1 16 14 16.9 14 18S13.1 20 12 20 10 19.1 10 18 10.9 16 12 16M15 10C16.1 10 17 10.9 17 12S16.1 14 15 14 13 13.1 13 12 13.9 10 15 10M6 16C7.1 16 8 16.9 8 18S7.1 20 6 20 4 19.1 4 18 4.9 16 6 16M9 10C10.1 10 11 10.9 11 12S10.1 14 9 14 7 13.1 7 12 7.9 10 9 10M12 4C13.1 4 14 4.9 14 6S13.1 8 12 8 10 7.1 10 6 10.9 4 12 4M18 16C19.1 16 20 16.9 20 18S19.1 20 18 20 16 19.1 16 18 16.9 16 18 16'
                    fill='#F7B801' />
                </svg>
              </div>
              <span className='table-3__indicator-select-name'>
                  {t('string.translate.ph_rastvor_name3')}
                </span>
            </button>
            <button className={`table-3__indicator-select table-3__indicator-select--4 ${selectedIndicator === 3 && 'table-3__indicator-select--current'}`} onClick={() => setSelectedIndicator(3)}>
              <div className='table-3__indicator-select-icon'>
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M4 22C3.44772 22 3 21.5523 3 21L3 17C3 16.4477 3.44772 16 4 16H9V22H4Z' fill='#9248D6'
                        fill-opacity='0.85' />
                  <path d='M9 22V16H15V22H9Z' fill='#9248D6' fill-opacity='0.45' />
                  <path d='M15 22V16H20C20.5523 16 21 16.4477 21 17V21C21 21.5523 20.5523 22 20 22H15Z'
                        fill='#9248D6'
                        fill-opacity='0.25' />
                  <path d='M4 15C3.44772 15 3 14.5523 3 14L3 10C3 9.44772 3.44772 9 4 9H9V15H4Z' fill='#9248D6'
                        fill-opacity='0.9' />
                  <path d='M9 15V9H15V15H9Z' fill='#9248D6' fill-opacity='0.7' />
                  <path d='M15 15V9H20C20.5523 9 21 9.44772 21 10V14C21 14.5523 20.5523 15 20 15H15Z' fill='#9248D6'
                        fill-opacity='0.4' />
                  <path d='M4 8C3.44772 8 3 7.55228 3 7L3 3C3 2.44772 3.44772 2 4 2H9V8H4Z' fill='#9248D6' />
                  <path d='M9 8V2H15V8H9Z' fill='#9248D6' fill-opacity='0.9' />
                  <path d='M15 8V2H20C20.5523 2 21 2.44772 21 3V7C21 7.55228 20.5523 8 20 8H15Z' fill='#9248D6'
                        fill-opacity='0.7' />
                </svg>
              </div>
              <span className='table-3__indicator-select-name'>
                  {t('string.translate.ph_rastvor_name0')}
                </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  </>);
};

export default Table2;