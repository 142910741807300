import { Helmet } from 'react-helmet';
import GoToHomePage from './GoToHomePage';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Table2 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t('string.translate.el_config').replace(':', '')} – ${t(
          'string.translate.name_ab'
        )} ${new Date().getFullYear()}`}</title>
      </Helmet>
      <section className='table-2'>
        <GoToHomePage classNames='go-to-homepage go-to-homepage__full-size' />
        <div className='table-2__modal'>
          <div className='table-2__modal-wrapper'>
            <div className='table-2__header'>
              <Link className='table-2__close-button' to='/tables' />
              <h2 className='table-2__title'>
                {t('string.translate.el_config').replace(':', '')}
              </h2>
            </div>
            <div className='table-2__content'>
              <img className='table2__image'
                   src={require('../assets/images/electronic-config.svg').default}
                   alt=''
                   draggable="false"
              />
              <p className='table-2__description table-2__description--1'>
                {t('string.translate.table_el_config_descr')}
              </p>
              <p className='table-2__description table-2__description--2'>
                {t('string.translate.table_el_config_descr2')}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Table2;