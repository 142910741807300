import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import tableEN from './translate/table/en/table.json';
import tableDE from './translate/table/de/table.json';
import tableKO from './translate/table/ko/table.json';
import tablePT_PT from './translate/table/pt-pt/table.json';
import tablePT_BR from './translate/table/pt-br/table.json';
import tableRU from './translate/table/ru/table.json';
import tableES from './translate/table/es/table.json';
import tableHI from './translate/table/hi/table.json';
import tableZH_CN from './translate/table/zh-cn/table.json';
import tableZH_TW from './translate/table/zh-tw/table.json';
import tableBE from './translate/table/be/table.json';
import tableCS from './translate/table/cs/table.json';
import tableFIL from './translate/table/fil/table.json';
import tableFR from './translate/table/fr/table.json';
import tableIT from './translate/table/it/table.json';
import tableJA from './translate/table/ja/table.json';
import tableKK from './translate/table/kk/table.json';
import tablePL from './translate/table/pl/table.json';
import tableTH from './translate/table/th/table.json';
import tableTR from './translate/table/tr/table.json';
import tableUK from './translate/table/uk/table.json';
import stringEN from './translate/language/en/string_en.json';
import stringDE from './translate/language/de/string_de.json';
import stringKO from './translate/language/ko/string_ko.json';
import stringPT_PT from './translate/language/pt-pt/string_pt-pt.json';
import stringPT_BR from './translate/language/pt-br/string_pt-br.json';
import stringRU from './translate/language/ru/string_ru.json';
import stringES from './translate/language/es/string_es.json';
import stringHI from './translate/language/hi/string_hi.json';
import stringZH_CN from './translate/language/zh-cn/string_zh-cn.json';
import stringZH_TW from './translate/language/zh-tw/string_zh-tw.json';
import stringBE from './translate/language/be/string_be.json';
import stringCS from './translate/language/cs/string_cs.json';
import stringFIL from './translate/language/fil/string_fil.json';
import stringFR from './translate/language/fr/string_fr.json';
import stringIT from './translate/language/it/string_it.json';
import stringJA from './translate/language/ja/string_ja.json';
import stringKK from './translate/language/kk/string_kk.json';
import stringPL from './translate/language/pl/string_pl.json';
import stringTH from './translate/language/th/string_th.json';
import stringTR from './translate/language/tr/string_tr.json';
import stringUK from './translate/language/uk/string_uk.json';
import arrayEN from './translate/language/en/array_en.json';
import arrayDE from './translate/language/de/array_de.json';
import arrayKO from './translate/language/ko/array_ko.json';
import arrayPT_PT from './translate/language/pt-pt/array_pt-pt.json';
import arrayPT_BR from './translate/language/pt-br/array_pt-br.json';
import arrayRU from './translate/language/ru/array_ru.json';
import arrayES from './translate/language/es/array_es.json';
import arrayHI from './translate/language/hi/array_hi.json';
import arrayZH_CN from './translate/language/zh-cn/array_zh-cn.json';
import arrayZH_TW from './translate/language/zh-tw/array_zh-tw.json';
import arrayBE from './translate/language/be/array_be.json';
import arrayCS from './translate/language/cs/array_cs.json';
import arrayFIL from './translate/language/fil/array_fil.json';
import arrayFR from './translate/language/fr/array_fr.json';
import arrayIT from './translate/language/it/array_it.json';
import arrayJA from './translate/language/ja/array_ja.json';
import arrayKK from './translate/language/kk/array_kk.json';
import arrayPL from './translate/language/pl/array_pl.json';
import arrayTH from './translate/language/th/array_th.json';
import arrayTR from './translate/language/tr/array_tr.json';
import arrayUK from './translate/language/uk/array_uk.json';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: {
          periodic_table_1: 'Periodic',
          periodic_table_2: 'Table',
          table: tableEN,
          string: stringEN,
          array: arrayEN
        }
      },
      de: {
        translation: {
          periodic_table_1: 'Periodensystem',
          periodic_table_2: '',
          table: tableDE,
          string: stringDE,
          array: arrayDE
        }
      },
      ko: {
        translation: {
          periodic_table_1: '주기율표',
          periodic_table_2: '',
          table: tableKO,
          string: stringKO,
          array: arrayKO
        }
      },
      pt_PT: {
        translation: {
          periodic_table_1: 'Tabela',
          periodic_table_2: 'Periódica',
          table: tablePT_PT,
          string: stringPT_PT,
          array: arrayPT_PT
        }
      },
      pt_BR: {
        translation: {
          periodic_table_1: 'Tabela',
          periodic_table_2: 'Periódica',
          table: tablePT_BR,
          string: stringPT_BR,
          array: arrayPT_BR
        }
      },
      ru: {
        translation: {
          periodic_table_1: 'Таблица',
          periodic_table_2: 'Менделеева',
          table: tableRU,
          string: stringRU,
          array: arrayRU
        }
      },
      es: {
        translation: {
          periodic_table_1: 'Tabla',
          periodic_table_2: 'Periódica',
          table: tableES,
          string: stringES,
          array: arrayES
        }
      },
      hi: {
        translation: {
          periodic_table_1: 'आवर्त सारणी',
          periodic_table_2: '',
          table: tableHI,
          string: stringHI,
          array: arrayHI
        }
      },
      zh_CN: {
        translation: {
          periodic_table_1: '元素周期表',
          periodic_table_2: '',
          table: tableZH_CN,
          string: stringZH_CN,
          array: arrayZH_CN
        }
      },
      zh_TW: {
        translation: {
          periodic_table_1: '元素週期表',
          periodic_table_2: '',
          table: tableZH_TW,
          string: stringZH_TW,
          array: arrayZH_TW
        }
      },
      be: {
        translation: {
          periodic_table_1: 'Табліца',
          periodic_table_2: 'Мендзялеева',
          table: tableBE,
          string: stringBE,
          array: arrayBE
        }
      },
      cs: {
        translation: {
          periodic_table_1: 'Periodická',
          periodic_table_2: 'Tabulka',
          table: tableCS,
          string: stringCS,
          array: arrayCS
        }
      },
      fil: {
        translation: {
          periodic_table_1: 'Periodic',
          periodic_table_2: 'Table',
          table: tableFIL,
          string: stringFIL,
          array: arrayFIL
        }
      },
      fr: {
        translation: {
          periodic_table_1: 'Tableau',
          periodic_table_2: 'Périodique',
          table: tableFR,
          string: stringFR,
          array: arrayFR
        }
      },
      it: {
        translation: {
          periodic_table_1: 'Tavola',
          periodic_table_2: 'Periodica',
          table: tableIT,
          string: stringIT,
          array: arrayIT
        }
      },
      ja: {
        translation: {
          periodic_table_1: '周期表',
          periodic_table_2: ' ',
          table: tableJA,
          string: stringJA,
          array: arrayJA
        }
      },
      kk: {
        translation: {
          periodic_table_1: 'Менделеев',
          periodic_table_2: 'Кестесі',
          table: tableKK,
          string: stringKK,
          array: arrayKK
        }
      },
      pl: {
        translation: {
          periodic_table_1: 'Układ',
          periodic_table_2: 'Okresowy',
          table: tablePL,
          string: stringPL,
          array: arrayPL
        }
      },
      th: {
        translation: {
          periodic_table_1: 'Periodic',
          periodic_table_2: 'Table',
          table: tableTH,
          string: stringTH,
          array: arrayTH
        }
      },
      tr: {
        translation: {
          periodic_table_1: 'Periyodik',
          periodic_table_2: 'Tablo',
          table: tableTR,
          string: stringTR,
          array: arrayTR
        }
      },
      uk: {
        translation: {
          periodic_table_1: 'Таблиця',
          periodic_table_2: 'Менделєєва',
          table: tableUK,
          string: stringUK,
          array: arrayUK
        }
      }
    },

    fallbackLng: 'en',
  });