import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import GoToHomePage from './GoToHomePage';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

const Table1 = () => {
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] = useState('all');

  const returnSymbol = string => {
    return string.substr(0, 1);
  };

  return (
    <>
      <Helmet>
        <title>{`${t('string.translate.table_table_rastvor')} – ${t(
          'string.translate.name_ab'
        )} ${new Date().getFullYear()}`}</title>
      </Helmet>
      <section className='table-1'>
        <GoToHomePage classNames='go-to-homepage go-to-homepage__full-size' />
        <div className='table-1__modal'>
          <div className='table-1__modal-wrapper'>
            <div className='table-1__header'>
              <Link className='table-1__close-button' to='/tables' />
              <h2 className='table-1__title'>
                {t('string.translate.table_table_rastvor')}
              </h2>
              {selectedGroup !== 'all' &&
                <button className='table-1__reset-button' onClick={() => setSelectedGroup('all')} />}
            </div>
            <div className='table-1__table-container'>
              <div className='table-1__top'>
                <span className='table-1__table-header table-1__table-header--top'>
                  H<sup>+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Li<sup>+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  NH<sup>4+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  K<sup>+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Na<sup>+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Ag<sup>+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Ba<sup>2+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Ca<sup>2+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Mg<sup>2+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Zn<sup>2+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Mn<sup>2+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Cu<sup>2+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Cu<sup>+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Hg<sup>+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Hg<sup>2+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Pb<sup>2+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Fe<sup>2+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Fe<sup>3+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Al<sup>3+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Cr<sup>3+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Bi<sup>3+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Sn<sup>2+</sup>
                </span>
                <span className='table-1__table-header table-1__table-header--top'>
                  Sr<sup>2+</sup>
                </span>
              </div>
              <div className='table-1__table-wrapper'>
                <div className='table-1__left'>
                  <span className='table-1__table-header table-1__table-header--left'>
                    OH<sup>–</sup>
                  </span>
                  <span className='table-1__table-header table-1__table-header--left'>
                    NO<sub>3</sub>
                    <sup>–</sup>
                  </span>
                  <span className='table-1__table-header table-1__table-header--left'>
                    F<sup>–</sup>
                  </span>
                  <span className='table-1__table-header table-1__table-header--left'>
                    Cl<sup>–</sup>
                  </span>
                  <span className='table-1__table-header table-1__table-header--left'>
                    Br<sup>–</sup>
                  </span>
                  <span className='table-1__table-header table-1__table-header--left'>
                    I<sup>–</sup>
                  </span>
                  <span className='table-1__table-header table-1__table-header--left'>
                    S<sup>2–</sup>
                  </span>
                  <span className='table-1__table-header table-1__table-header--left'>
                    SO<sub>3</sub>
                    <sup>2–</sup>
                  </span>
                  <span className='table-1__table-header table-1__table-header--left'>
                    SO<sub>4</sub>
                    <sup>2–</sup>
                  </span>
                  <span className='table-1__table-header table-1__table-header--left'>
                    CO<sub>3</sub>
                    <sup>2–</sup>
                  </span>
                  <span className='table-1__table-header table-1__table-header--left'>
                    SiO<sub>3</sub>
                    <sup>2–</sup>
                  </span>
                  <span className='table-1__table-header table-1__table-header--left'>
                    PO<sub>4</sub>
                    <sup>3–</sup>
                  </span>
                  <span className='table-1__table-header table-1__table-header--left'>
                    CrO<sub>4</sub>
                    <sup>2–</sup>
                  </span>
                </div>
                <table className='table-1__table'>
                  <tr>
                    <td className='table-1__table-cell'>*</td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__table-cell--soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group1'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__table-cell--insoluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group3'))}
                    </td>
                    <td
                      className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__table-cell--decomposed' : ''}`}>
                      –
                    </td>
                    <td className={`table-1__table-cell ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__table-cell--slightly-soluble' : ''}`}>
                      {returnSymbol(t('string.translate.rastvor_group2'))}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className='table-1__legend'>
            <ul className='table-1__legend-list'>
              <li className='table-1__legend-item' onClick={() => setSelectedGroup('soluble')}>
                <span className={`table-1__legend-symbol ${selectedGroup === 'all' || selectedGroup === 'soluble' ? 'table-1__legend-symbol--soluble' : ''}`}>
                  {returnSymbol(t('string.translate.rastvor_group1'))}
                </span>
                <span className='table-1__legend-name'>
                  {t('string.translate.rastvor_group1')}
                </span>
              </li>
              <li className='table-1__legend-item' onClick={() => setSelectedGroup('slightlySoluble')}>
                <span className={`table-1__legend-symbol ${selectedGroup === 'all' || selectedGroup === 'slightlySoluble' ? 'table-1__legend-symbol--slightly-soluble' : ''}`}>
                  {returnSymbol(t('string.translate.rastvor_group2'))}
                </span>
                <span className='table-1__legend-name'>
                  {t('string.translate.rastvor_group2')}
                </span>
              </li>
              <li className='table-1__legend-item' onClick={() => setSelectedGroup('insoluble')}>
                <span className={`table-1__legend-symbol ${selectedGroup === 'all' || selectedGroup === 'insoluble' ? 'table-1__legend-symbol--insoluble' : ''}`}>
                  {returnSymbol(t('string.translate.rastvor_group3'))}
                </span>
                <span className='table-1__legend-name'>
                  {t('string.translate.rastvor_group3')}
                </span>
              </li>
              <li className='table-1__legend-item' onClick={() => setSelectedGroup('decomposedByWater')}>
                <span className={`table-1__legend-symbol ${selectedGroup === 'all' || selectedGroup === 'decomposedByWater' ? 'table-1__legend-symbol--decomposed' : ''}`}>
                  –
                </span>
                <span className='table-1__legend-name'>
                  {t('string.translate.rastvor_group4')}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Table1;
