import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GoToHomePage from './GoToHomePage';
import { Helmet } from 'react-helmet';

const Table5 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t('string.translate.table_poly_uglerod_name')} – ${t(
          'string.translate.name_ab'
        )} ${new Date().getFullYear()}`}</title>
      </Helmet>
      <section className='table-5'>
        <GoToHomePage classNames='go-to-homepage go-to-homepage__full-size' />
        <div className='table-5__modal'>
          <div className='table-5__header'>
            <Link className='table-5__close-button' to='/tables' />
            <h2 className='table-5__title'>
              {t('string.translate.table_poly_uglerod_name')}
            </h2>
          </div>
          <ul className='table-5__list'>
            <li className='table-5__item table-5__item--naphthalene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_1.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item0').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item0')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--acenaphthene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_2.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item1').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item1')}
                  </span>
                  <span className='table-5__item-value'>0.000347g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--fluorene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_3.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item2').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item2')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--phenanthrene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_4.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item3').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item3')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--anthracene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_5.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item4').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item4')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--fluoranthene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_6.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item5').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item5')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--pyrene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_7.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item6').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item6')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--benzo-anthracene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_8.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item7').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item7')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--chrysene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_9.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item8').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item8')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--benzo-b-fluoranthene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_10.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item9').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item9')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--benzo-k-fluoranthene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_11.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item10').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item10')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--dibenzo-anthracene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_12.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item11').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item11')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--benzo-perylene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_13.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item12').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item12')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
            <li className='table-5__item table-5__item--indeno-pyrene'>
              <img
                className='table-5__item-image'
                src={require(`../assets/images/polyaromatic-carbons/poly_14.png`)}
                alt=''
              />
              <p className='table-5__item-info'>
                <span className='table-5__item-symbol'>
                  {t('array.poly_uglerod_name.item13').substring(0, 1)}
                </span>
                <span className='table-5__info-wrapper'>
                  <span className='table-5__item-name'>
                    {t('array.poly_uglerod_name.item13')}
                  </span>
                  <span className='table-5__item-value'>0.0031g/100mL</span>
                </span>
              </p>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Table5;
