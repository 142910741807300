import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import GoToHomePage from '../components/GoToHomePage.js';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setFontSizeVariable } from '../services/functions';
import { useAuth } from '../hooks/useAuth';

const SettingsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [fontSize, setFontFize] = useState(
    localStorage.getItem('readElementFontSize') === null
      ? 1
      : localStorage.getItem('readElementFontSize')
  );
  const maxFontSize = 2;

  const getBackgroundSize = () => {
    return {
      backgroundSize: `${(fontSize * 100) / maxFontSize}% 100%`
    };
  };

  useEffect(() => {
    localStorage.setItem('readElementFontSize', fontSize.toString());
    setFontSizeVariable();
  }, [fontSize]);

  const { logout, isAuthenticated } = useAuth();

  return (
    <>
      <Helmet>
        <title>{`${t('string.translate.dm_left4_1')} – ${t(
          'string.translate.name_ab'
        )} ${new Date().getFullYear()}`}</title>
      </Helmet>
      <GoToHomePage />
      <section className='settings'>
        <div className='settings__container'>
          <div className='settings__header'>
            <h2 className='settings__title'>
              {t('string.translate.dm_left4_1')}
            </h2>
            <h3 className='settings__section-title'>
              {t('string.translate.sett_base')}
            </h3>
            <ul className='settings__list'>
              <li className='settings__item'>
                <Link
                  to='settings/language'
                  className='settings__item-link settings__item-link--language'
                >
                  <h4 className='settings__item-title'>
                    {t('string.translate.pref_lang_name')}
                  </h4>
                  <p className='settings__item-description'>
                    {t('string.translate.pref_lang_descr_new')}
                  </p>
                </Link>
              </li>
              <li className='settings__item settings__item--font-size'>
                <h4 className='settings__item-title'>
                  {t('string.translate.sett_text_size')}
                </h4>
                <p className='settings__item-description'>
                  {t('string.translate.sett_text_size2')}
                </p>
              </li>
              <li className='settings__item settings__item--range'>
                <div className='settings__range-wrapper'>
                  <input
                    className='settings__range-input'
                    type='range'
                    name='font-size'
                    id='font-size-input'
                    min='0'
                    max={maxFontSize}
                    step='1'
                    onChange={e => setFontFize(e.target.value)}
                    style={getBackgroundSize()}
                    value={fontSize}
                  />
                  <div className='settings__range-values'>
                    <span className='settings__range-value'>
                      {t('string.translate.text_size_small')}
                    </span>
                    <span className='settings__range-value settings__range-value--normal'>
                      {t('string.translate.text_size_normal')}
                    </span>
                    <span className='settings__range-value'>
                      {t('string.translate.text_size_big')}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <h3 className='settings__section-title settings__section-title--extra'>
              {t('string.translate.sett_dopoln')}
            </h3>
            <ul className='settings__list'>
              <li className='settings__item'>
                <a
                  href='mailto:nks199731@gmail.com'
                  className='settings__item-link settings__item-link--report'
                  target='_blank'
                  rel='noreferrer'
                >
                  <h4 className='settings__item-title'>
                    {t('string.translate.dm_left3')}
                  </h4>
                  <p className='settings__item-description'>
                    {t('string.translate.sett_error')}
                  </p>
                </a>
              </li>
              <li className='settings__item'>
                <a
                  href='https://play.google.com/store/apps/details?id=mendeleev.redlime'
                  className='settings__item-link settings__item-link--android'
                  target='_blank'
                  rel='noreferrer'
                >
                  <h4 className='settings__item-title'>
                    Application for Android
                  </h4>
                  <p className='settings__item-description'>
                    {t('string.translate.sett_update2')}
                  </p>
                </a>
              </li>
              <li className='settings__item'>
                <a
                  href='http://itunes.apple.com/app/id1451726577'
                  className='settings__item-link settings__item-link--ios'
                  target='_blank'
                  rel='noreferrer'
                >
                  <h4 className='settings__item-title'>Application for iOS</h4>
                  <p className='settings__item-description'>
                    {t('string.translate.sett_update2')}
                  </p>
                </a>
              </li>
            </ul>
            <h3 className='settings__section-title settings__section-title--images'>
              {t('string.translate.setting_image_header')}
            </h3>
            <ul className='settings__list'>
              <li className='settings__item'>
                <a
                  href='https://images-of-elements.com/'
                  className='settings__item-link settings__item-link--image-elements'
                  target='_blank'
                  rel='noreferrer'
                >
                  <h4 className='settings__item-title'>
                    {t('string.translate.setting_image_title')}
                  </h4>
                  <p className='settings__item-description'>
                    https://images-of-elements.com
                  </p>
                </a>
              </li>
              <li className='settings__item'>
                <a
                  href='http://personal.psu.edu/a0j/periodic4.html'
                  className='settings__item-link settings__item-link--emission-spectrum'
                  target='_blank'
                  rel='noreferrer'
                >
                  <h4 className='settings__item-title'>
                    {t('string.translate.setting_image_spectr')}
                  </h4>
                  <p className='settings__item-description'>
                    http://personal.psu.edu
                  </p>
                </a>
              </li>
            </ul>
            <h3 className='settings__section-title settings__section-title--version'>
              {t('string.translate.version_app')}
            </h3>
            <ul className='settings__list'>
              <li className='settings__item'>
                <a
                  href='https://chernykh.tech/pt-web'
                  className='settings__item-link settings__item-link--about'
                  target='_blank'
                  rel='noreferrer'
                >
                  <h4 className='settings__item-title'>
                    {t('string.translate.dm_left5')}
                  </h4>
                  <p className='settings__item-description'>
                    {t('string.translate.sett_about')}
                  </p>
                </a>
              </li>
              <li className='settings__item'>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className='settings__item-link settings__item-link--version'
                  target='_blank'
                  rel='noreferrer'
                >
                  <h4 className='settings__item-title'>Periodic Table - WEB</h4>
                  <p className='settings__item-description'>3.5</p>
                </a>
              </li>
              {isAuthenticated &&
                <li className='settings__item'>
                  <button className='settings__item-link settings__item-link--logout' onClick={() => {
                    logout();
                    history.push('/');
                  }}>
                    {t('string.translate.web_session_finish')}
                  </button>
                </li>
              }
            </ul>
            <p className='copyright'>
              ©{' '}
              <a
                className='copyright__author-link'
                href='https://chernykh.tech/'
                target='_blank'
                rel='noreferrer'
              >
                chernykh.tech
              </a>{' '}
              / All rights reserved, {`${new Date().getFullYear()}`} <br />
              Developed by:{' '}
              <a
                href='https://t.me/yegor_firstov'
                className='copyright__developer-link'
                target='_blank'
                rel='noreferrer'
              >
                Yegor Firstov
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default SettingsPage;
