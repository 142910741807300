import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QrModal from './QrModal';
import { useAuth } from '../hooks/useAuth';
import { useRecoilState } from 'recoil';
import { qrModalState } from '../states/atoms';

const MainMenu = () => {
  const { t } = useTranslation();
  const [qrModal, setQrModal] = useRecoilState(qrModalState);
  const {isAuthenticated} = useAuth();
  const history = useHistory();

  return (
    <>
      <aside className='main-menu'>
        <div className='main-menu__container'>
          <nav className='main-menu__navigation navigation'>
            <ul className='navigation__list'>
              <li className='navigation__item'>
                <NavLink
                  to='/'
                  exact
                  className='navigation__link navigation__link--periodic-table'
                  activeClassName='navigation__link--current navigation__link--current--pt'
                />
              </li>
              <li className='navigation__item'>
                <NavLink
                  exact
                  to='/search'
                  className='navigation__link navigation__link--search'
                  activeClassName='navigation__link--current navigation__link--current--search'
                >
                  {t('string.translate.menu_tab2')}
                </NavLink>
              </li>
              <li className='navigation__item'>
                <NavLink
                  exact
                  to='/element-list'
                  className='navigation__link navigation__link--list'
                  activeClassName='navigation__link--current navigation__link--current--list'
                >
                  {t('string.translate.menu_tab3')}
                </NavLink>
              </li>
              <li className='navigation__item'>
                <NavLink
                  exact
                  to='/tables'
                  className='navigation__link navigation__link--tables'
                  activeClassName='navigation__link--current navigation__link--current--tables'
                >
                  {t('string.translate.menu_tab4')}
                </NavLink>
              </li>
              <li className='navigation__item navigation__item--settings'>
                <NavLink
                  exact
                  to='/settings'
                  className='navigation__link navigation__link--settings'
                  activeClassName='navigation__link--current navigation__link--current--settings'
                />
              </li>
              {!isAuthenticated && <li className='navigation__link navigation__link--qr'>
                <button className='qr-modal__qr-button' onClick={() => {
                  setQrModal(!qrModal);
                  history.push('/');
                }} />
              </li>}
            </ul>
          </nav>
        </div>
      </aside>
      {qrModal && <QrModal onClick={() => setQrModal(!qrModal)} />}
    </>
  );
};

export default MainMenu;
