import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Properties from './Properties';
import TableFilters from './TableFilters';
import TableElementCell from './TableElementCell';
import { elementCategories } from '../constants/elementCategories';
import { useAuth } from '../hooks/useAuth';
import { useRecoilState } from 'recoil';
import { allElementsState } from '../states/atoms';

const PeriodicTable = () => {
  const { isAuthenticated } = useAuth();
  const [elements] = useRecoilState(allElementsState);
  const [currentCategory, setCurrentCategory] = useState('all');
  const { t } = useTranslation();
  const [propertiesModal, setPropertiesModal] = useState(false);
  const [currentElementProp, setCurrentElementProp] = useState({
    property: 'name',
    label: t('string.translate.table_cell_property_name')
  });

  const showElements = (from, to) => {
    const elementsToShow = [];

    for (let i = from - 1; i < to; i++) {
      elementsToShow.push(
        <TableElementCell
          key={i} // Remember to provide a unique key for each child element in React
          number={elements[i].number}
          symbol={elements[i].symbol}
          radioactive={elements[i].OverviewCommon.elementRadioactivity}
          property={setElementProperty(elements[i].number)}
          classNames={
            currentCategory === elementCategories[i] ||
            currentCategory === 'all'
              ? `periodic-table__element element ${elementCategories[i]}} ${elementCategories[i]}--selected`
              : `periodic-table__element element ${elementCategories[i]}`
          }
        />
      );
    }

    return elementsToShow;
  };

  const setValue = event => setCurrentCategory(event.target.value);

  const setElementProperty = (elementNumber) => {
    switch (currentElementProp.property) {
      case 'name':
        return t(`table.element${elementNumber}.name`);
      case 'elementMasses':
        return Number(elements[elementNumber - 1].PropertiesCommon.elementMasse).toFixed(4) || '—';
      case 'elValences':
        return elements[elementNumber - 1].PropertiesCommon.elValence || '—';
      case 'elementOpenedYear':
        return elements[elementNumber - 1].OverviewCommon.elementOpenedYear || '—';
      default:
        return t(`table.element${elementNumber}.name`);
    }
  };

  return (
    <section className='periodic-table__container'>
      <header className='header'>
        <div className='header__logo'>
          <p className='header__periodic-table'>{`${t('periodic_table_1')} ${t('periodic_table_2')}`}</p>
          {isAuthenticated &&
            <p className='header__version'>Professional</p>
          }
        </div>
        <div className='header__property-button-wrapper'>
          {(currentCategory !== 'all' || currentElementProp.property !== 'name') && (
            <button
              className='header__reset-button'
              onClick={() => {
                setCurrentCategory('all');
                setCurrentElementProp({
                  property: 'name',
                  label: t('string.translate.table_cell_property_name')
                });
              }} />
          )}
          <button className='properties__button' onClick={() => setPropertiesModal(!propertiesModal)}>
            {currentElementProp.label}
          </button>
        </div>
      </header>
      <div className='periodic-table__table-wrapper'>
        <table className='periodic-table'>
          <tbody>
          <tr>
            <td />
            <td className='periodic-table__column-number'>1</td>
            <td colSpan='16' />
            <td className='periodic-table__column-number'>18</td>
          </tr>
          <tr>
            <td className='periodic-table__row-number'>1</td>
            <TableElementCell
              number={elements[0].number}
              symbol={elements[0].symbol}
              property={setElementProperty(elements[0].number)}
              classNames={
                currentCategory === 'G' ||
                currentCategory === 'all'
                  ? 'periodic-table__element element G G--selected'
                  : 'periodic-table__element element G'
              }
            />
            <td className='periodic-table__column-number'>2</td>
            <td
              className='periodic-table__categories categories'
              rowSpan='2'
              colSpan='10'
            >
              <TableFilters setValue={setValue} currentCategory={currentCategory} />
            </td>
            <td className='periodic-table__column-number'>13</td>
            <td className='periodic-table__column-number'>14</td>
            <td className='periodic-table__column-number'>15</td>
            <td className='periodic-table__column-number'>16</td>
            <td className='periodic-table__column-number'>17</td>
            <TableElementCell
              number={elements[1].number}
              symbol={elements[1].symbol}
              property={setElementProperty(elements[1].number)}
              classNames={
                currentCategory === 'I' ||
                currentCategory === 'all'
                  ? 'periodic-table__element element I I--selected'
                  : 'periodic-table__element element I'
              }
            />
          </tr>
          <tr>
            <td className='periodic-table__row-number'>2</td>
            {showElements(3, 10)}
          </tr>
          <tr>
            <td className='periodic-table__row-number'>3</td>
            {showElements(11, 12)}
            <td className='periodic-table__column-number'>3</td>
            <td className='periodic-table__column-number'>4</td>
            <td className='periodic-table__column-number'>5</td>
            <td className='periodic-table__column-number'>6</td>
            <td className='periodic-table__column-number'>7</td>
            <td className='periodic-table__column-number'>8</td>
            <td className='periodic-table__column-number'>9</td>
            <td className='periodic-table__column-number'>10</td>
            <td className='periodic-table__column-number'>11</td>
            <td className='periodic-table__column-number'>12</td>
            {showElements(13, 18)}
          </tr>
          <tr>
            <td className='periodic-table__row-number'>4</td>
            {showElements(19, 36)}
          </tr>
          <tr>
            <td className='periodic-table__row-number'>5</td>
            {showElements(37, 54)}
          </tr>
          <tr>
            <td className='periodic-table__row-number'>6</td>
            {showElements(55, 56)}
            <td className='periodic-table__transfer'>
              57-71 <br />{' '}
              <span className='periodic-table__transfer-symbols'>La-Lu</span>
            </td>
            {showElements(72, 86)}
          </tr>
          <tr>
            <td className='periodic-table__row-number'>7</td>
            {showElements(87, 88)}
            <td className='periodic-table__transfer'>
              89-103 <br />{' '}
              <span className='periodic-table__transfer-symbols'>Ac-Lr</span>
            </td>
            {showElements(104, 118)}
          </tr>
          <tr>
            <td className='periodic-table__row-number'>8</td>
            {showElements(119, 120)}
            <td className='periodic-table__transfer'>
              121-126 <br />{' '}
              <span className='periodic-table__transfer-symbols'>Ubu-Ubh</span>
            </td>
          </tr>
          <tr>
            <td
              className='element__legend-cell'
              rowSpan='3'
              colSpan='3'
              align='center'
            >
              {currentElementProp.property !== 'name' ? (
                <div className='element__legend'>
                  <span className='element__number'>
                    {t('array.spinner_filter.item3')}
                  </span>
                  <span className='element__symbol'>
                    {t('array.spinner_filter.item2')}
                  </span>
                  <span className='element__name'>{currentElementProp.label}</span>
                </div>
              ) : null}
            </td>
            {showElements(57, 71)}
          </tr>
          <tr>
            {showElements(89, 103)}
          </tr>
          <tr>
            {showElements(121, 127)}
          </tr>
          </tbody>
        </table>
      </div>
      {propertiesModal &&
        <Properties
          onClick={() => setPropertiesModal(!propertiesModal)}
          setCurrentElementProp={setCurrentElementProp}
        />
      }
    </section>
  );
};

export default PeriodicTable;
