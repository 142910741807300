import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloudIcon from '../assets/images/cloud-icon.svg';
import { useAuth } from '../hooks/useAuth';
import { getAllElements } from '../services/getAllElements';
import { useRecoilState } from 'recoil';
import { allElementsState } from '../states/atoms';
import { io } from 'socket.io-client';
import { generateSVGString } from '@intosoft/qrcode';

const QrModal = ({ onClick }) => {
  const { t } = useTranslation();
  const [socket, setSocket] = useState(null);
  const { isAuthenticated, setIsAuthenticated, setAuthCookie, getAuthCookie } = useAuth();
  const [, setAllElementsData] = useRecoilState(allElementsState);
  const [qrConfig, setQrConfig] = useState({
    length: 160,
    padding: 15,
    borderRadius: 20,
    errorCorrectionLevel: 'M',
    value: '',
    shapes: {
      eyeFrame: 'rounded',
      eyeball: 'rounded',
      body: 'circle-small'
    },
    colors: {
      background: '#fff',
      body: 'rgba(252, 88, 17, 1)',
      eyeFrame: {
        topLeft: 'body',
        topRight: 'body',
        bottomLeft: 'body'
      },
      eyeball: {
        topLeft: 'body',
        topRight: 'body',
        bottomLeft: 'body'
      }
    }
  });
  const qrCode = generateSVGString(qrConfig);

  const getAllElementsData = async (token) => {
    const allElements = await getAllElements(token);
    setAllElementsData(allElements);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      const newSocket = io(process.env.REACT_APP_WS_HOST);
      setSocket(newSocket);

      newSocket.on('number', (data) => {

        if (data && !data.statusSuccess) {
          setQrConfig((prevState) => ({
            ...prevState,
            value: `pt-pro://${data.client_Id}-${data.qr_code}`
          }));
        }

        if (data.statusSuccess) {
          setAuthCookie(data.qr_code);
          setIsAuthenticated(true);

          newSocket.off('number');
        }
      });

      return () => {
        newSocket.off('number');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseClick = () => {
    if (socket) {
      socket.disconnect();
    }
    getAllElementsData(getAuthCookie());
    onClick();
  };

  return (
    <section className='qr-modal'>
      <div className='properties__background' onClick={handleCloseClick} />
      <div className='qr-modal__modal'>
        <div className='qr-modal__modal-wrapper'>
          <div className={`qr-modal__header ${isAuthenticated && 'qr-modal__header--success'}`}>
            {isAuthenticated ?
              <>
                <div className='qr-modal__header-text'>
                  <h2 className='qr-modal__title'>
                    {t('string.translate.qr_modal2_title')}
                  </h2>
                  <p className='qr-modal__description'>
                    {t('string.translate.qr_modal2_subtitle')}
                  </p>
                </div>
                <div className='qr-modal__cloud-image'>
                  <img className='qr-modal__cloud' src={CloudIcon} alt='' />
                </div>
              </> :
              <>
                <div className='qr-modal__header-text'>
                  <h2 className='qr-modal__title'>
                    {t('string.translate.qr_modal1_title')}
                  </h2>
                  <p className='qr-modal__description'>
                    {t('string.translate.qr_modal1_subtitle')}
                  </p>
                </div>
                {qrConfig.value &&
                  <div className='qr-modal__qr-wrapper'>
                    <div
                      style={{ borderRadius: '8px', overflow: 'hidden', width: 160, height: 160 }}
                      dangerouslySetInnerHTML={{ __html: qrCode }}
                    />
                  </div>
                }
              </>
            }
          </div>
          <div className='qr-modal__content'>
            <ul className='qr-modal__list'>
              <li className='qr-modal__item'>
                <h3 className='qr-modal__item-title'>{t('string.translate.qr_modal_item1_1')}</h3>
                <p className='qr-modal__item-description'>{t('string.translate.qr_modal_item1_2')}</p>
              </li>
              <li className='qr-modal__item'>
                <h3 className='qr-modal__item-title'>{t('string.translate.qr_modal_item2_1')}</h3>
                <p className='qr-modal__item-description'>{t('string.translate.qr_modal_item2_2')}</p>
              </li>
              <li className='qr-modal__item'>
                <h3 className='qr-modal__item-title'>{t('string.translate.qr_modal_item3_1')}</h3>
                <p className='qr-modal__item-description'>{t('string.translate.qr_modal_item3_2')}</p>
              </li>
              <li className='qr-modal__item'>
                <p className='qr-modal__item-description'>{t('string.translate.qr_modal_message')}</p>
              </li>
            </ul>
          </div>
          <button
            className={`qr-modal__close-button ${isAuthenticated && 'qr-modal__close-button--success'}`}
            onClick={handleCloseClick}
          />
        </div>
      </div>
    </section>
  );
};

export default QrModal;