import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const CookieNotification = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  const acceptCookie = () => {
    localStorage.setItem('acceptCookie', 'accepted');
    setIsVisible(false);
  };

  useEffect(() => {
    if (localStorage.getItem('acceptCookie') === 'accepted') {
      setIsVisible(false);
    }
  }, []);

  return isVisible &&
    <div className='cookie-notification'>
      <div className='cookie-notification__modal-wrapper'>
        <div className='cookie-notification__modal'>
          <div className='cookie-notification__modal-content'>
            <h2 className='cookie-notification__title'>{t('string.translate.cookie_title')}</h2>
            <div className='cookie-notification__text-wrapper'>
              <p className='cookie-notification__text'>
                {t('string.translate.cookie_subtitle')} {' '}
                <a
                  href='https://chernykh.tech/privacy-policy'
                  className='cookie-notification__link'
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('string.translate.cookie_privacy')}
                </a>
              </p>
              <button
                className='cookie-notification__button'
                onClick={acceptCookie}
              >
                {t('string.translate.cookie_btn1')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>;
};

export default CookieNotification;