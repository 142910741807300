import { Link } from 'react-router-dom';

const TableElementCell = ({number, symbol, property, classNames, radioactive}) => {

  return (
    <td className={classNames}>
      <Link to={`/element/${number}`} className='element__link'>
        <span className='element__number'>{number}</span>
        {radioactive && <span className='radioactive' />}
        <span className='element__symbol'>{symbol}</span>
        <span className='element__name'>{property}</span>
      </Link>
    </td>
  )
}

export default TableElementCell;