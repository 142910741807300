export const getNFPA1 = position => {
  const nfpa1 = [
    "array.nfpa1.item0",
    "array.nfpa1.item1",
    "array.nfpa1.item2",
    "array.nfpa1.item3",
    "array.nfpa1.item4"
  ];

  return nfpa1[position];
};

export const getNFPA2 = position => {
  const nfpa2 = [
    "array.nfpa2.item0",
    "array.nfpa2.item1",
    "array.nfpa2.item2",
    "array.nfpa2.item3",
    "array.nfpa2.item4"
  ];

  return nfpa2[position];
};

export const getNFPA3 = position => {
  const nfpa3 = [
    "array.nfpa3.item0",
    "array.nfpa3.item1",
    "array.nfpa3.item2",
    "array.nfpa3.item3",
    "array.nfpa3.item4"
  ];

  return nfpa3[position];
};


export const getNFPA4 = data => {
  const nfpa4 = [
    "array.nfpa4.item0",
    "array.nfpa4.item1"
  ];

  let final = "";

  if (data !== "-") {
    if (data.includes("W")) {
      final = nfpa4[0];
    } else if (data.includes("OX")) {
      final = nfpa4[1];
    }
  } else {
    final = "----";
  }

  return final;
};
