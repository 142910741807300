import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GoToHomePage from './GoToHomePage';
import { Helmet } from 'react-helmet';

const Table4 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t('string.translate.svva_alkanov_ab')} – ${t(
          'string.translate.name_ab'
        )} ${new Date().getFullYear()}`}</title>
      </Helmet>
      <section className='table-4'>
        <GoToHomePage classNames='go-to-homepage go-to-homepage__full-size' />
        <div className='table-4__modal'>
          <div className='table-4__header'>
            <Link className='table-4__close-button' to='/tables' />
            <h2 className='table-4__title'>
              {t('string.translate.svva_alkanov_ab')}
            </h2>
          </div>
          <ul className='table-4__list'>
            <li className='table-4__item table-4__item--methane'>
              <p className='table-4__item-name-and-density table-4__item-name-and-density--methane'>
                <span className='table-4__item-name'>
                  {t('array.svva_alkanov.item0')}
                </span>
                <span className='table-4__item-density'>
                  {t('string.translate.svva_alkanov_plotnost')} 0.415{' '}
                  {t('string.translate.read_gramm_santim')}
                </span>
              </p>
              <p className='table-4__item-melting-and-boiling'>
                <span className='table-4__item-boiling'>
                  {t('string.translate.svva_alkanov_kip')} -161.5
                  <span className='celsius'>°C</span>
                </span>
                <span className='table-4__item-melting'>
                  {t('string.translate.svva_alkanov_plav')} -182.5
                  <span className='celsius'>
                    <span className='celsius'>°C</span>
                  </span>
                </span>
              </p>
            </li>
            <li className='table-4__item table-4__item--ethane'>
              <p className='table-4__item-name-and-density table-4__item-name-and-density--ethane'>
                <span className='table-4__item-name'>
                  {t('array.svva_alkanov.item1')}
                </span>
                <span className='table-4__item-density'>
                  {t('string.translate.svva_alkanov_plotnost')} 0.561{' '}
                  {t('string.translate.read_gramm_santim')}
                </span>
              </p>
              <p className='table-4__item-melting-and-boiling'>
                <span className='table-4__item-boiling'>
                  {t('string.translate.svva_alkanov_kip')} -88.6
                  <span className='celsius'>°C</span>
                </span>
                <span className='table-4__item-melting'>
                  {t('string.translate.svva_alkanov_plav')} -182.8
                  <span className='celsius'>°C</span>
                </span>
              </p>
            </li>
            <li className='table-4__item table-4__item--propane'>
              <p className='table-4__item-name-and-density table-4__item-name-and-density--propane'>
                <span className='table-4__item-name'>
                  {t('array.svva_alkanov.item2')}
                </span>
                <span className='table-4__item-density'>
                  {t('string.translate.svva_alkanov_plotnost')} 0.583{' '}
                  {t('string.translate.read_gramm_santim')}
                </span>
              </p>
              <p className='table-4__item-melting-and-boiling'>
                <span className='table-4__item-boiling'>
                  {t('string.translate.svva_alkanov_kip')} -42.1
                  <span className='celsius'>°C</span>
                </span>
                <span className='table-4__item-melting'>
                  {t('string.translate.svva_alkanov_plav')} -187.6
                  <span className='celsius'>°C</span>
                </span>
              </p>
            </li>
            <li className='table-4__item table-4__item--butane'>
              <p className='table-4__item-name-and-density table-4__item-name-and-density--butane'>
                <span className='table-4__item-name'>
                  {t('array.svva_alkanov.item3')}
                </span>
                <span className='table-4__item-density'>
                  {t('string.translate.svva_alkanov_plotnost')} 0.500{' '}
                  {t('string.translate.read_gramm_santim')}
                </span>
              </p>
              <p className='table-4__item-melting-and-boiling'>
                <span className='table-4__item-boiling'>
                  {t('string.translate.svva_alkanov_kip')} -0.5
                  <span className='celsius'>°C</span>
                </span>
                <span className='table-4__item-melting'>
                  {t('string.translate.svva_alkanov_plav')} -138.3
                  <span className='celsius'>°C</span>
                </span>
              </p>
            </li>
            <li className='table-4__item table-4__item--isobutane'>
              <p className='table-4__item-name-and-density table-4__item-name-and-density--isobutane'>
                <span className='table-4__item-name'>
                  {t('array.svva_alkanov.item4')}
                </span>
                <span className='table-4__item-density'>
                  {t('string.translate.svva_alkanov_plotnost')} 0.563{' '}
                  {t('string.translate.read_gramm_santim')}
                </span>
              </p>
              <p className='table-4__item-melting-and-boiling'>
                <span className='table-4__item-boiling'>
                  {t('string.translate.svva_alkanov_kip')} -11.7
                  <span className='celsius'>°C</span>
                </span>
                <span className='table-4__item-melting'>
                  {t('string.translate.svva_alkanov_plav')} -159.4
                  <span className='celsius'>°C</span>
                </span>
              </p>
            </li>
            <li className='table-4__item table-4__item--pentane'>
              <p className='table-4__item-name-and-density table-4__item-name-and-density--pentane'>
                <span className='table-4__item-name'>
                  {t('array.svva_alkanov.item5')}
                </span>
                <span className='table-4__item-density'>
                  {t('string.translate.svva_alkanov_plotnost')} 0.626{' '}
                  {t('string.translate.read_gramm_santim')}
                </span>
              </p>
              <p className='table-4__item-melting-and-boiling'>
                <span className='table-4__item-boiling'>
                  {t('string.translate.svva_alkanov_kip')} 36.07
                  <span className='celsius'>°C</span>
                </span>
                <span className='table-4__item-melting'>
                  {t('string.translate.svva_alkanov_plav')} -129.7
                  <span className='celsius'>°C</span>
                </span>
              </p>
            </li>
            <li className='table-4__item table-4__item--isopentane'>
              <p className='table-4__item-name-and-density table-4__item-name-and-density--isopentane'>
                <span className='table-4__item-name'>
                  {t('array.svva_alkanov.item6')}
                </span>
                <span className='table-4__item-density'>
                  {t('string.translate.svva_alkanov_plotnost')} 0.620{' '}
                  {t('string.translate.read_gramm_santim')}
                </span>
              </p>
              <p className='table-4__item-melting-and-boiling'>
                <span className='table-4__item-boiling'>
                  {t('string.translate.svva_alkanov_kip')} 27.9
                  <span className='celsius'>°C</span>
                </span>
                <span className='table-4__item-melting'>
                  {t('string.translate.svva_alkanov_plav')} -159.9
                  <span className='celsius'>°C</span>
                </span>
              </p>
            </li>
            <li className='table-4__item table-4__item--neopentane'>
              <p className='table-4__item-name-and-density table-4__item-name-and-density--neopentane'>
                <span className='table-4__item-name'>
                  {t('array.svva_alkanov.item7')}
                </span>
                <span className='table-4__item-density'>
                  {t('string.translate.svva_alkanov_plotnost')} 0.613{' '}
                  {t('string.translate.read_gramm_santim')}
                </span>
              </p>
              <p className='table-4__item-melting-and-boiling'>
                <span className='table-4__item-boiling'>
                  {t('string.translate.svva_alkanov_kip')} 9.5
                  <span className='celsius'>°C</span>
                </span>
                <span className='table-4__item-melting'>
                  {t('string.translate.svva_alkanov_plav')} -16.6
                  <span className='celsius'>°C</span>
                </span>
              </p>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Table4;
