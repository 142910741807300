export const countries = [
  { code: "GB", flag: "🇬🇧", name: "string.translate.opener_country_gb" },
  { code: "SE", flag: "🇸🇪", name: "string.translate.opener_country_se" },
  { code: "DE", flag: "🇩🇪", name: "string.translate.opener_country_de" },
  { code: "US", flag: "🇺🇸", name: "string.translate.opener_country_us" },
  { code: "FR", flag: "🇫🇷", name: "string.translate.opener_country_fr" },
  { code: "RU", flag: "", name: "string.translate.opener_country_ru" },
  { code: "MiddleEast", flag: "", name: "string.translate.opener_country_middleeast" },
  { code: "CH", flag: "🇨🇭", name: "string.translate.opener_country_ch" },
  { code: "DK", flag: "🇩🇰", name: "string.translate.opener_country_dk" },
  { code: "AT", flag: "🇦🇹", name: "string.translate.opener_country_at" },
  { code: "EG", flag: "🇪🇬", name: "string.translate.opener_country_eg" },
  { code: "Middle", flag: "", name: "string.translate.opener_country_middle" },
  { code: "MX", flag: "🇲🇽", name: "string.translate.opener_country_mx" },
  { code: "Anatolia", flag: "", name: "string.translate.opener_country_anatolia" },
  { code: "IndiaContinent", flag: "", name: "string.translate.opener_country_indiacontinent" },
  { code: "FI", flag: "🇫🇮", name: "string.translate.opener_country_fi" },
  { code: "IT", flag: "🇮🇹", name: "string.translate.opener_country_it" },
  { code: "AsiaMinor", flag: "", name: "string.translate.opener_country_asiaminor" },
  { code: "Kestel", flag: "", name: "string.translate.opener_country_kestel" },
  { code: "RO", flag: "🇷🇴", name: "string.translate.opener_country_ro" },
  { code: "ES", flag: "🇪🇸", name: "string.translate.opener_country_es" },
  { code: "PE", flag: "🇵🇪", name: "string.translate.opener_country_pe" },
  { code: "NahalQana", flag: "", name: "string.translate.opener_country_nahalqana" }
];
