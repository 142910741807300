import { atom } from 'recoil';

export const authState = atom({
  key: 'authState',
  default: false,
});

export const allElementsState = atom({
  key: 'allElementsState',
  default: []
});

export const qrModalState = atom({
  key: 'qrModalState',
  default: false
});