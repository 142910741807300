export const elementsTheme = [
  true,
  true,
  true, //3
  false,
  false,
  true, //6
  true,
  true,
  true, //9
  true,
  true,
  true, //12
  true, //13
  true, //14
  true, //15
  true, //16
  false,
  true,
  true, //19
  true, //20
  true, //21
  true, //22
  true, //23
  true, //24
  true, //25
  true, //26
  true, //27
  true, //28
  true, //29
  false,
  false,
  true, //32
  true, //33
  true, //34
  true, //35
  true,
  true, //37
  true, //38
  false,
  false,
  false,
  false,
  false, //43
  true, //44
  false,
  true, //46
  true, //47
  false,
  true, //49
  false,
  true, //51
  true, //52
  false,
  true,
  false,
  true, //56
  false,
  false,
  true, //59
  true, //60
  true,
  true, //62
  true, //63
  false,
  true,
  true, //66
  true, //67
  true, //68
  true, //69
  true, //70
  false,
  true, //Hf 72
  true, //73
  true, //74
  true,
  true, //76
  true, //77
  false,
  true, //Au 79
  true, //80
  true, //81
  true, //82
  true, //83
  true, //84
  false,
  true,
  true,
  true,
  true,
  true, //90
  true, //91
  true,
  true,
  true,
  true,
  true,
  true,
  true, //98
  true,
  true,
  true,
  true, //102
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  false,
  false,
  false,
  false,
  false,
  false,
  false
];
