import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReadElementProps from '../components/ReadElementProps.js';
import { Helmet } from 'react-helmet';
import GoToHomePage from '../components/GoToHomePage.js';
import i18next from 'i18next';
import { changeCategoryColor } from '../services/functions';
import { elementsTheme } from '../constants/elementsTheme';
import { useEffect, useRef, useState } from 'react';
import { elementCategories } from '../constants/elementCategories';
import { useAuth } from '../hooks/useAuth';
import Loader from '../components/Loader';

//Elements
const ReadElementHeader = styled.div`
    height: 300px;
    position: relative;
    padding-bottom: 20px;
    border-bottom: 2px solid;
    border-color: ${props => props.borderColor};

    @media (min-width: 1600px) {
        height: 380px;
    }

    @media (min-width: 1900px) {
        height: 420px;
    }

    &::after {
        content: '';
        width: 28px;
        height: 28px;
        position: absolute;
        z-index: 1;
        left: 50%;
        margin-left: -14px;
        bottom: -30px;
        border: 14px solid transparent;
        border-top-color: ${props => props.borderColor};
    }
`;

const ReadElementImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.backgroundColor};
    z-index: -1;
`;

const Separator = styled.span`
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: ${props => props.bgColor};
    border-radius: 50%;
`;

const CategoryCard = styled.div`
    font-weight: 700;
    font-family: 'OpenSans', 'Arial', sans-serif;
    display: inline-block;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    padding: 12px 20px;
    border-radius: 4px 0 0 4px;
    font-size: 12px;
    text-transform: uppercase;
`;

const SmallHeaderSymbol = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'OpenSans', 'Arial', sans-serif;
    font-weight: 700;
    font-size: 17px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    margin-right: 19px;
    margin-left: 10px;
`;

const SmallHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    border-bottom: 2px solid;
    border-color: ${props => props.borderColor};
    position: relative;

    &::after {
        content: '';
        width: 28px;
        height: 28px;
        position: absolute;
        z-index: 1;
        left: 50%;
        margin-left: -14px;
        bottom: -30px;
        border: 14px solid transparent;
        border-top-color: ${props => props.borderColor};
    }
`;

const ReadElementPage = ({ id }) => {
  const itemId = Number(id);
  const { t } = useTranslation();
  const { getAuthCookie } = useAuth();
  const [showHeader, setShowHeader] = useState(false);
  const headerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  const api = `${process.env.REACT_APP_API_HOST}/periodic/element/${itemId}`;

  const getElementData = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json'
      };

      const token = getAuthCookie();
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }

      await fetch(api, {
        method: 'GET',
        headers
      })
        .then(data => data.json())
        .then(data => setData(data));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getElementData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  useEffect(() => {
    if (!isLoading) {
      const container = document.querySelector('.read-element__container');
      const handleScroll = () => {
        if (container.scrollTop > (headerRef.current.clientHeight + 60)) {
          setShowHeader(true);
        } else {
          setShowHeader(false);
        }
      };

      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isLoading]);

  const getCategoryName = (itemId) => {
    switch (elementCategories[itemId - 1]) {
      case 'A':
        return t(`string.translate.read_cat1`);
      case 'B':
        return t(`string.translate.read_cat2`);
      case 'C':
        return t(`string.translate.read_cat3`);
      case 'D':
        return t(`string.translate.read_cat4`);
      case 'E':
        return t(`string.translate.read_cat5`);
      case 'G':
        return t(`string.translate.read_cat6`);
      case 'H':
        return t(`string.translate.read_cat7`);
      case 'I':
        return t(`string.translate.read_cat8`);
      case 'J':
        return t(`string.translate.read_cat9`);
      case 'K':
        return t(`string.translate.read_cat10`);
      case 'L':
        return t(`string.translate.read_cat11`);
      default:
        return t(`string.translate.read_cat11`);
    }
  };

  const setElementTheme = () => {
    if (elementsTheme[itemId - 1] === true) {
      return 'read-element__header--white';
    } else if (elementsTheme[itemId - 1] === false) {
      return 'read-element__header--dark';
    }
  };

  const changeWhiteColor = (category) => {
    switch (category) {
      case 'L':
        return '#000000';
      default:
        return '#FFFFFF';
    }
  };

  return (<>
    <Helmet>
      <title>{`${itemId} | ${t(`table.element${itemId}.symbol`)} – ${t(`table.element${itemId}.name`)}`}</title>
    </Helmet>
    <GoToHomePage classNames={'go-to-homepage__full-size'} />
    <section className='read-element'>
      {!isLoading ? (<>
        <div className={`read-element__small-header ${showHeader ? 'visible' : 'hidden'}`}>
          <SmallHeaderContainer borderColor={changeCategoryColor(elementCategories[itemId - 1])}>
            <div className='read-element__small-header-wrapper'>
              <Link className='read-element__go-back-btn' to='/' />
              <SmallHeaderSymbol backgroundColor={() => changeCategoryColor(elementCategories[itemId - 1])}
                                 textColor={() => changeWhiteColor(elementCategories[itemId - 1])}>
                {data.symbol}
              </SmallHeaderSymbol>
              <div className='read-element__small-info-wrapper'>
                <div className='read-element__small-element-name'>
                  {t(`table.element${itemId}.name`)}
                </div>
                <div className='read-element__small-element-weight'>
                  {data.PropertiesCommon.elementMasse}
                  <span> {t(`string.translate.read_gramm_moll`)}</span>
                </div>
              </div>
            </div>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
              className='read-element__small-wiki-link'
              href={`https://${i18next.language}.wikipedia.org/wiki/${t(`table.element${itemId}.name`)}`}
              aria-label='Wiki link'
              target='_blank'
              rel='noreferrer'
            />
          </SmallHeaderContainer>
          <div className='read-element__buttons'>
            {itemId <= 1 ? (<div className='read-element__element-link--prev-plug' />) : (<Link
              to={`/element/${itemId - 1}`}
              className='read-element__element-link read-element__element-link--prev'
            >
              <span className='read-element__button-element-number'>
                {itemId - 1}
              </span>
              <Separator bgColor={changeCategoryColor(elementCategories[itemId - 2])} />
              <span className='read-element__button-element-name'>
                {t(`table.element${itemId - 1}.name`)}
              </span>
            </Link>)}
            {itemId >= 127 ? null : (<Link
              to={`/element/${itemId + 1}`}
              className='read-element__element-link read-element__element-link--next'
            >
              <span className='read-element__button-element-number'>
                {itemId + 1}
              </span>
              <Separator bgColor={changeCategoryColor(elementCategories[itemId])} />
              <span className='read-element__button-element-name'>
                {t(`table.element${itemId + 1}.name`)}
              </span>
            </Link>)}
          </div>
        </div>
        <div className='read-element__container'>
          <ReadElementHeader borderColor={changeCategoryColor(elementCategories[itemId - 1])} ref={headerRef}>
            <div className='read-element__gradient' />
            <ReadElementImage
              backgroundColor={changeCategoryColor(elementCategories[itemId - 1])}
            />
            <div className='read-element__content'>
              <div className={`read-element__header ${setElementTheme()}`}>
                <Link className='read-element__go-back-btn' to='/' />
                <div className='read-element__buttons-wrapper'>
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <a
                    className='read-element__wiki'
                    href={`https://${i18next.language}.wikipedia.org/wiki/${t(`table.element${itemId}.name`)}`}
                    aria-label='Wiki link'
                    target='_blank'
                    rel='noreferrer'
                  />
                  {/*<button className="read-element__compare-btn"/>*/}
                </div>
              </div>
              <div className='read-element__info-container'>
                <div className='read-element__selected-element'>
                <span className='read-element__name'>
                  {t(`table.element${itemId}.name`)}
                </span>
                  <div className='read-element__symbol-wrapper'>
                  <span className='read-element__symbol'>
                    {data.symbol}
                  </span>
                    <span className='read-element__number'>
                    {itemId}
                  </span>
                  </div>
                  <span className='read-element__weight'>
                  {data.PropertiesCommon.elementMasse}
                    <span> {t(`string.translate.read_gramm_moll`)}</span>
                </span>
                </div>
                <div className='read-element__groups'>
                  {data.OverviewCommon.elementRadioactivity && (<span className='read-element__radioactive'>
                    {t(`string.translate.ys1`).replace(':', '')}
                  </span>)}
                  <CategoryCard
                    backgroundColor={changeCategoryColor(elementCategories[itemId - 1])}
                    textColor={changeWhiteColor(elementCategories[itemId - 1])}
                  >
                  <span className='read-element__category'>
                    {getCategoryName(itemId)}
                  </span>
                  </CategoryCard>
                </div>
              </div>
            </div>
            {(data && data.number === itemId && data.OverviewCommon.hasImage) && (<picture>
              <source
                type='image/avif'
                srcSet={`${require(`../assets/images/elements-avif/element_${itemId}.avif`)} 1x,
        				${require(`../assets/images/elements-2x-avif/element_${itemId}.avif`)} 2x
        				`}
              />
              <source
                type='image/webp'
                srcSet={`${require(`../assets/images/elements-webp/element_${itemId}.webp`)} 1x,
        				${require(`../assets/images/elements-2x-webp/element_${itemId}.webp`)} 2x
        				`}
              />
              <img
                className='read-element__element-image'
                src={require(`../assets/images/elements-jpg/element_${itemId}.jpg`)}
                srcSet={`${require(`../assets/images/elements-2x-jpg/element_${itemId}.jpg`)} 2x`}
                alt=''
              />
            </picture>)}
          </ReadElementHeader>
          <div className='read-element__buttons'>
            {itemId <= 1 ? (<div className='read-element__element-link--prev-plug' />) : (<Link
              to={`/element/${itemId - 1}`}
              className='read-element__element-link read-element__element-link--prev'
            >
              <span className='read-element__button-element-number'>
                {itemId - 1}
              </span>
              <Separator bgColor={changeCategoryColor(elementCategories[itemId - 2])} />
              <span className='read-element__button-element-name'>
                {t(`table.element${itemId - 1}.name`)}
              </span>
            </Link>)}
            {itemId >= 127 ? null : (<Link
              to={`/element/${itemId + 1}`}
              className='read-element__element-link read-element__element-link--next'
            >
              <span className='read-element__button-element-number'>
                {itemId + 1}
              </span>
              <Separator bgColor={changeCategoryColor(elementCategories[itemId])} />
              <span className='read-element__button-element-name'>
                {t(`table.element${itemId + 1}.name`)}
              </span>
            </Link>)}
          </div>
          <ReadElementProps data={data} itemId={itemId} />
        </div>
      </>) : <div className='read-element__loader-wrapper'>
        <Loader itemId={itemId} size='small' />
      </div>}
    </section>
  </>);
};

export default ReadElementPage;
