export const colors = [
  'FFFFFF',
  'C6C6C6',
  '181818',
  'FFB600',
  '838383',
  'F3501D',
  'D40000',
  'F1D302',
  'ACAD94',
  '8E418C',
  'B4D060'
];
