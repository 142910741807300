import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import GoToHomePage from '../components/GoToHomePage.js';
import {
  addShadowOnScroll,
  changeCategoryColor,
  changeWhiteColor
} from '../services/functions.js';
import { languageList } from '../constants/languages';
import { Link } from 'react-router-dom';

const ElementSymbol = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'OpenSans', 'Arial', sans-serif;
  font-weight: 700;
  font-size: 17px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  margin-right: 19px;
`;

const LanguageSelectPage = () => {
  const { t } = useTranslation();
  const changeAppLanguage = lng => {
    i18next.changeLanguage(lng);
  };
  let [term, setTerm] = useState('');

  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(false);
  const ref = useRef();

  useEffect(() => {
    addShadowOnScroll(setShowStart, setShowEnd, ref);
  }, []);

  const getItems = () => {
    let items = [];
    languageList.forEach((language, i) => {
      items.push(
        <li
          className='language-select__language-item'
          key={i}
          content={language.englishName}
        >
          <div
            className='search__elements-item'
            onClick={() => changeAppLanguage(language.symbol)}
          >
            <ElementSymbol
              backgroundColor={() => changeCategoryColor(language.colorId)}
              textColor={() => changeWhiteColor(language.colorId)}
            >
              {language.symbol.split('_')[0].toUpperCase()}
            </ElementSymbol>
            <div className='search__element-name-and-mass'>
              <span className='search__element-name'>
                {language.englishName}
              </span>
              <span className='search__element-mass'>
                {language.originalName}
              </span>
            </div>
          </div>
        </li>
      );
    });
    return items;
  };

  const search = (items, term) => {
    if (term.length === 0) {
      return items;
    }

    const result = items.filter(item => {
      return (
        item.props.children.props.children[0].props.children
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1 ||
        item.props.content.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        item.props.children.props.children[1].props.children[0].props.children
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1 ||
        item.props.children.props.children[1].props.children[1].props.children
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1
      );
    });

    if (result.length !== 0) {
      return result;
    } else {
      return (
        <div className='search__not-found'>
          <img
            className='search__not-found-image'
            src={require('../assets/images/nothing-found-icon.svg').default}
            alt=''
          />
          <p className='search__not-found-text'>
            {t('string.translate.not_found_search')}
          </p>
        </div>
      );
    }
  };

  const onSearchChange = e => {
    setTerm(e.target.value);
  };

  const onSearchClear = () => {
    setTerm('');
  };

  return (
    <>
      <Helmet>
        <title>{`${t('string.translate.menu_tab2')} – ${t(
          'string.translate.name_ab'
        )} ${new Date().getFullYear()}`}</title>
      </Helmet>
      <GoToHomePage />
      <section className='search'>
        <div
          className={`search__container ${showStart && 'page--start'} ${
            showEnd && 'page--end'
          }`}
          ref={ref}
        >
          <div className='language-select__header'>
            <Link className='read-element__go-back-btn' to='/settings' />
            <form className='search__form'>
              <input
                className='search__input'
                type='input'
                name='search'
                onChange={onSearchChange}
                id='search-input'
                autoComplete='off'
                placeholder={t(`string.translate.pref_lang_name`)}
              />
              <button
                className='language-select__reset-button'
                onClick={onSearchClear}
                type='reset'
              />
            </form>
          </div>
          <ul className='search__elements-list'>{search(getItems(), term)}</ul>
        </div>
      </section>
    </>
  );
};

export default LanguageSelectPage;
