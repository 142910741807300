import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import GoToHomePage from '../components/GoToHomePage.js';
import {
  changeCategoryColor,
  changeWhiteColor,
  useOutsideClick
} from '../services/functions.js';
import { elementCategories } from '../constants/elementCategories';
import { useRecoilState } from 'recoil';
import { allElementsState, qrModalState } from '../states/atoms';
import { useAuth } from '../hooks/useAuth';

const ElementSymbol = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'OpenSans', 'Arial', sans-serif;
    font-weight: 700;
    font-size: 17px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    margin-right: 19px;
`;

const SearchPage = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const [, setQrModal] = useRecoilState(qrModalState);
  const [elements] = useRecoilState(allElementsState);
  const [inputValue, setInputValue] = useState('');
  const [searchParameter, setSearchParameter] = useState('all');
  const [parametersList, setParametersList] = useState(false);
  const openParametersList = value => setParametersList(value);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, openParametersList);

  const getItems = () => {
    let items = [];
    elements.forEach(element => {
      const proElementProperties = isAuthenticated ? {
        'data-density': element.PropertiesCommon.elementDensity,
        'data-electro': element.ElectromagneticCommon.es_electro,
        'data-cas': element.OverviewCommon.casNumber,
        'data-electronegativity': element.ReactivityCommon.electronegativity,
        'data-radius': element.AtomicCommon.atomicRadius,
        'data-electrons': element.OverviewCommon.elementE,
      } : {};

      items.push(
        <li
          key={element.number}
          data-name={t(`table.element${element.number}.name`)}
          data-latin-name={element.OverviewCommon.latinName}
          data-symbol={element.symbol}
          data-year={element.OverviewCommon.elementOpenedYear}
          data-weight={element.PropertiesCommon.elementMasse}
          data-number={element.number.toString()}
          {...proElementProperties}
        >
          <Link
            className='search__elements-item'
            to={`/element/${element.number}`}
          >
            <ElementSymbol
              backgroundColor={() => changeCategoryColor(elementCategories[element.number - 1])}
              textColor={() => changeWhiteColor(elementCategories[element.number - 1])}
            >
              {element.symbol}
            </ElementSymbol>
            <div className='search__element-name-and-mass'>
              <span className='search__element-name'>{t(`table.element${element.number}.name`)}</span>
              <span className='search__element-mass'>
                {`${element.PropertiesCommon.elementMasse} ${t('string.translate.read_gramm_moll')}`}
              </span>
            </div>
          </Link>
        </li>
      );
    });

    return items;
  };

  const sortSearchList = (a, b) =>
    a.props['data-symbol'].localeCompare(b.props['data-symbol']);
  const search = (items, inputValue) => {
    if (inputValue.length === 0) {
      return items.sort((a, b) => sortSearchList(a, b));
    }

    const result = items.filter(item => {
      if (searchParameter === 'all') {
        return (
          item.props['data-name']
            .toLowerCase()
            .indexOf(inputValue.toLowerCase()) > -1 ||
          item.props['data-latin-name']
            .toLowerCase()
            .indexOf(inputValue.toLowerCase()) > -1 ||
          item.props['data-symbol']
            .toLowerCase()
            .indexOf(inputValue.toLowerCase()) > -1 ||
          item.props['data-number']
            .toLowerCase()
            .indexOf(inputValue.toLowerCase()) > -1 ||
          item.props['data-weight']
            .toLowerCase()
            .indexOf(inputValue.toLowerCase()) > -1 ||
          (typeof item.props['data-year'] === 'string' &&
            item.props['data-year']
              .toLowerCase()
              .indexOf(inputValue.toLowerCase()) > -1)
        );
      } else {
        if (typeof item.props[`data-${searchParameter}`] === 'string') {
          return (
            item.props[`data-${searchParameter}`]
              .toLowerCase()
              .indexOf(inputValue.toLowerCase()) > -1
          );
        }
      }
      return false;
    });

    if (result.length !== 0) {
      return result.sort((a, b) => sortSearchList(a, b));
    } else if (
      result.length === 0 &&
      (inputValue === 'Залупий' || inputValue === 'залупий')
    ) {
      return (
        <div className='search__not-found'>
          <img
            className='search__not-found-image'
            src={require('../assets/images/joke.jpg')}
            alt=''
          />
          <p className='search__not-found-text'>
            Залупий и еще несколько элементов для тебя, умник :/
          </p>
        </div>
      );
    } else {
      return (
        <div className='search__not-found'>
          <img
            className='search__not-found-image'
            src={require('../assets/images/nothing-found-icon.svg').default}
            alt=''
          />
          <p className='search__not-found-text'>
            {t('string.translate.not_found_search')}
          </p>
        </div>
      );
    }
  };

  const onSearchParameterChange = (parameter) => {
    if (isAuthenticated) {
      setSearchParameter(parameter);
      setParametersList(false);
    } else {
      history.push('/');
      setQrModal(true);
      setParametersList(false);
    }
  };

  const setInputPlaceholder = () => {
    switch (searchParameter) {
      case 'all':
        return t(`string.translate.search_hint`);
      case 'name':
        return `${t('array.spinner_filter.item1')}:`;
      case 'symbol':
        return `${t('array.spinner_filter.item2')}:`;
      case 'number':
        return `${t('array.spinner_filter.item3')}:`;
      case 'year':
        return `${t('array.spinner_filter.item4')}:`;
      case 'weight':
        return `${t('array.spinner_filter.item5')}:`;
      case 'density':
        return `${t('array.spinner_filter.item6')}:`;
      case 'electro':
        return `${t('array.spinner_filter.item7')}:`;
      case 'cas':
        return `${t('array.spinner_filter.item8')}:`;
      case 'electronegativity':
        return `${t('array.spinner_filter.item9')}:`;
      case 'radius':
        return `${t('array.spinner_filter.item10')}:`;
      case 'electrons':
        return `${t('array.spinner_filter.item11')}:`;
      default:
        return t(`string.translate.search_hint`);
    }
  };

  const onSearchChange = e => {
    if (
      searchParameter === 'all' ||
      searchParameter === 'name' ||
      searchParameter === 'symbol' ||
      searchParameter === 'cas'
    ) {
      setInputValue(e.target.value);
    } else {
      setInputValue(e.target.value.replace(/[^\d.]/g, ''));
    }
  };

  const onSearchClear = () => {
    setInputValue('');
  };

  return (
    <>
      <Helmet>
        <title>{`${t('string.translate.menu_tab2')} – ${t(
          'string.translate.name_ab'
        )} ${new Date().getFullYear()}`}</title>
      </Helmet>
      <GoToHomePage />
      <section className='search'>
        <div className='search__container'>
          <div className='search__header' ref={wrapperRef}>
            <div className='search__form'>
              <input
                className='search__input'
                type='input'
                name='search'
                onChange={onSearchChange}
                value={inputValue}
                id='search-input'
                autoComplete='off'
                placeholder={setInputPlaceholder()}
              />
              {inputValue && (
                <button
                  className='search__reset-button'
                  onClick={onSearchClear}
                />
              )}
              <button
                className='search__filters-button'
                onClick={() => setParametersList(!parametersList)}
              >
                <span
                  className={`search__filters-indicator search__filters-indicator--${searchParameter}`}
                />
              </button>
            </div>
            {parametersList && (
              <div className='search__parameters'>
                <ul className='search__parameters-list'>
                  <li className='search__parameter search__parameter--all'>
                    <button
                      onClick={() => {
                        setSearchParameter('all');
                        setParametersList(false);
                      }}
                    >
                      {t('array.spinner_filter.item0')}
                    </button>
                  </li>
                  <li className='search__parameter search__parameter--name'>
                    <button
                      onClick={() => {
                        setSearchParameter('name');
                        setParametersList(false);
                      }}
                    >
                      {t('array.spinner_filter.item1')}
                    </button>
                  </li>
                  <li className='search__parameter search__parameter--symbol'>
                    <button
                      onClick={() => {
                        setSearchParameter('symbol');
                        setParametersList(false);
                      }}
                    >
                      {t('array.spinner_filter.item2')}
                    </button>
                  </li>
                  <li className='search__parameter search__parameter--number'>
                    <button
                      onClick={() => {
                        setSearchParameter('number');
                        setParametersList(false);
                      }}
                    >
                      {t('array.spinner_filter.item3')}
                    </button>
                  </li>
                  <li className='search__parameter search__parameter--year'>
                    <button
                      onClick={() => {
                        setSearchParameter('year');
                        setParametersList(false);
                      }}
                    >
                      {t('array.spinner_filter.item4')}
                    </button>
                  </li>
                  <li className='search__parameter search__parameter--weight'>
                    <button
                      onClick={() => {
                        setSearchParameter('weight');
                        setParametersList(false);
                      }}
                    >
                      {t('array.spinner_filter.item5')}
                    </button>
                  </li>
                  <li
                    className={`search__parameter search__parameter--density ${!isAuthenticated && 'search__parameter--disabled'}`}>
                    <button onClick={() => onSearchParameterChange('density')}>
                      {t('array.spinner_filter.item6')}
                    </button>
                  </li>
                  <li
                    className={`search__parameter search__parameter--electro ${!isAuthenticated && 'search__parameter--disabled'}`}>
                    <button onClick={() => onSearchParameterChange('electro')}>
                      {t('array.spinner_filter.item7')}
                    </button>
                  </li>
                  <li
                    className={`search__parameter search__parameter--cas ${!isAuthenticated && 'search__parameter--disabled'}`}>
                    <button onClick={() => onSearchParameterChange('cas')}>
                      {t('array.spinner_filter.item8')}
                    </button>
                  </li>
                  <li
                    className={`search__parameter search__parameter--electronegativity ${!isAuthenticated && 'search__parameter--disabled'}`}>
                    <button onClick={() => onSearchParameterChange('electronegativity')}>
                      {t('array.spinner_filter.item9')}
                    </button>
                  </li>
                  <li
                    className={`search__parameter search__parameter--radius ${!isAuthenticated && 'search__parameter--disabled'}`}>
                    <button onClick={() => onSearchParameterChange('radius')}>
                      {t('array.spinner_filter.item10')}
                    </button>
                  </li>
                  <li
                    className={`search__parameter search__parameter--electrons ${!isAuthenticated && 'search__parameter--disabled'}`}>
                    <button onClick={() => onSearchParameterChange('electrons')}>
                      {t('array.spinner_filter.item11')}
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <ul className='search__elements-list'>
            {search(getItems(), inputValue)}
          </ul>
        </div>
      </section>
    </>
  );
};

export default SearchPage;
