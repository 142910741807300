import { Link } from 'react-router-dom';

const GoToHomePage = ({ classNames }) => {
  return (
    <Link to='/' className={`go-to-homepage ${classNames}`}>
      <div className='go-to-homepage__background' />
    </Link>
  );
};

export default GoToHomePage;
