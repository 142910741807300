export const languageList = [
  {
    symbol: 'en',
    englishName: 'English',
    originalName: 'English',
    colorId: 'A'
  },
  {
    symbol: 'de',
    englishName: 'German',
    originalName: 'Deutsche',
    colorId: 'B'
  },
  {
    symbol: 'ko',
    englishName: 'Korean',
    originalName: '한국어',
    colorId: 'C'
  },
  {
    symbol: 'pt_BR',
    englishName: 'Portuguese Brazil',
    originalName: 'Português Brasil',
    colorId: 'D'
  },
  {
    symbol: 'pt_PT',
    englishName: 'Portuguese',
    originalName: 'Português',
    colorId: 'E'
  },
  {
    symbol: 'ru',
    englishName: 'Russian',
    originalName: 'Русский',
    colorId: 'G'
  },
  {
    symbol: 'es',
    englishName: 'Spanish',
    originalName: 'Español',
    colorId: 'H'
  },
  {
    symbol: 'hi',
    englishName: 'Hindi',
    originalName: 'हिंदी',
    colorId: 'I'
  },
  {
    symbol: 'zh_CN',
    englishName: 'Chinese Simplified',
    originalName: '简体中文',
    colorId: 'J'
  },
  {
    symbol: 'zh_TW',
    englishName: 'Chinese Traditional',
    originalName: '中国传统的',
    colorId: 'K'
  },
  {
    symbol: 'be',
    englishName: 'Belorussian',
    originalName: 'Беларускі',
    colorId: 'C'
  },
  {
    symbol: 'cs',
    englishName: 'Czech',
    originalName: 'čeština',
    colorId: 'A'
  },
  {
    symbol: 'fil',
    englishName: 'Filipino',
    originalName: 'Filipino',
    colorId: 'B'
  },
  {
    symbol: 'fr',
    englishName: 'French',
    originalName: 'Française',
    colorId: 'C'
  },
  {
    symbol: 'it',
    englishName: 'Italian',
    originalName: 'Italiano',
    colorId: 'D'
  },
  {
    symbol: 'ja',
    englishName: 'Japanese',
    originalName: '日本',
    colorId: 'E'
  },
  {
    symbol: 'kk',
    englishName: 'Kazakh',
    originalName: 'Қазақша',
    colorId: 'G'
  },
  {
    symbol: 'pl',
    englishName: 'Polish',
    originalName: 'Polski',
    colorId: 'H'
  },
  {
    symbol: 'th',
    englishName: 'Thai',
    originalName: 'ไทย',
    colorId: 'I'
  },
  {
    symbol: 'tr',
    englishName: 'Turkish',
    originalName: 'Türk',
    colorId: 'J'
  },
  {
    symbol: 'uk',
    englishName: 'Ukrainian',
    originalName: 'Український',
    colorId: 'K'
  }
];
