import { USER_TOKEN } from '../constants/user';
import { authState } from '../states/atoms';
import { useRecoilState } from 'recoil';

const api = `${process.env.REACT_APP_API_HOST}/qr`;

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authState);

  const getAuthCookie = () => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${USER_TOKEN}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const setAuthCookie = (value) => {
    let expires = '';
    let date = new Date();
    date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
    expires = '; expires=' + date.toUTCString();
    document.cookie = `${USER_TOKEN}=${value || ''}${expires}; path=/; Secure; SameSite=Strict`;
  };

  const deleteAuthCookie = () => {
    document.cookie = `${USER_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  const checkIsAuth = async () => {
    try {
      await fetch(`${api}/check_auth/${getAuthCookie()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(data => data.json())
        .then(data => setIsAuthenticated(data.isAuthenticated))
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
    }
  };

  const logout = async () => {
    try {
      await fetch(`${api}/logout/${getAuthCookie()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(() => {
          setIsAuthenticated(false);
          deleteAuthCookie();
        })
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
    }
  }

  return { isAuthenticated, setIsAuthenticated, getAuthCookie, setAuthCookie, checkIsAuth, logout };
};