export const elementCategories = [
  'G', 'I',
  'A', 'B', 'E', 'G', 'G', 'G', 'H', 'I',
  'A', 'B', 'D', 'E', 'G', 'G', 'H', 'I',
  'A', 'B', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'D', 'E', 'E', 'G', 'H', 'I',
  'A', 'B', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'D', 'D', 'E', 'E', 'H', 'I',
  'A', 'B',
  'J', 'J', 'J', 'J', 'J', 'J', 'J', 'J', 'J', 'J', 'J', 'J', 'J', 'J', 'J',
  'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'D', 'D', 'D', 'E', 'H', 'I',
  'A', 'B',
  'K', 'K', 'K', 'K', 'K', 'K', 'K', 'K', 'K', 'K', 'K', 'K', 'K', 'K', 'K',
  'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'D', 'D', 'D', 'D', 'H', 'I',
  'A', 'B',
  'L', 'L', 'L', 'L', 'L', 'L', 'L'
];
