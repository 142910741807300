import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useOutsideClick } from '../services/functions';

const Properties = ({ onClick, setCurrentElementProp }) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, onClick);
  return (
    <section className='properties'>
      <div className='properties__background' onClick={onClick} />
      <div className='properties__modal' ref={wrapperRef}>
        <h3 className='properties__modal-title'>
          {t('string.translate.table_cell_property')}:
        </h3>
        <ul className='properties__list'>
          <li
            className='properties__item'
            onClick={() => {
              setCurrentElementProp({
                property: 'name',
                label: t('string.translate.table_cell_property_name')
              });
              onClick();
            }}
          >
            {t('string.translate.table_cell_property_name')}
          </li>
          <li
            className='properties__item'
            onClick={() => {
              setCurrentElementProp({
                property: 'elementMasses',
                label: t('string.translate.read_atom_massa').split(':')
              });
              onClick();
            }}
          >
            {t('string.translate.read_atom_massa').split(':')}
          </li>
          <li
            className='properties__item'
            onClick={() => {
              setCurrentElementProp({
                property: 'elValences',
                label: t('string.translate.rs2').split(':')
              });
              onClick();
            }}
          >
            {t('string.translate.rs2').split(':')}
          </li>
          <li
            className='properties__item'
            onClick={() => {
              setCurrentElementProp({
                property: 'elementOpenedYear',
                label: t('array.spinner_filter.item4')
              });
              onClick();
            }}
          >
            {t('array.spinner_filter.item4')}
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Properties;
